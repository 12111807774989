// src/components/AddDevice.js
import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';

function AddDevice({ onClose, menuItems, currentDevice }) {
  const [deviceId, setDeviceId] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [menuItemId, setMenuItemId] = useState('');

  // If we're editing, pre-fill the form with the current device's data
  useEffect(() => {
    if (currentDevice) {
      setDeviceId(currentDevice.deviceId);
      setStoreCode(currentDevice.storeCode || '');
      setDeviceName(currentDevice.deviceName);
      setMenuItemId(currentDevice.menuItemId || '');
    }
  }, [currentDevice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!deviceId || !deviceName) {
      alert('Please provide Device ID and Device Name.');
      return;
    }

    const newDevice = {
      deviceId,
      deviceName,
      storeCode,
      menuItemId: menuItemId || null, // If no menu item is selected, leave it as null
    };

    if (currentDevice) {
      // Update existing device
      await firestore.collection('cafeTVs').doc(currentDevice.id).update(newDevice);
    } else {
      // Add new device
      await firestore.collection('cafeTVs').add(newDevice);
    }

    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
        <h3 className="text-xl font-bold mb-4">{currentDevice ? 'Edit Device' : 'Add Device'}</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1">Device ID</label>
            <input
              type="text"
              disabled={currentDevice ? true : false}
              className="w-full border px-3 py-2 rounded"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Store Code</label>
            <input
              type="text"
              className="w-full border px-3 py-2 rounded"
              value={storeCode}
              onChange={(e) => setStoreCode(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Device Name</label>
            <input
              type="text"
              className="w-full border px-3 py-2 rounded"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Assign Menu Item</label>
            <select
              className="w-full border px-3 py-2 rounded"
              value={menuItemId}
              onChange={(e) => setMenuItemId(e.target.value)}
            >
              <option value="">-- Select Menu Item --</option>
              {menuItems.map(item => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded border"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-teal-500 text-white px-4 py-2 rounded"
            >
              {currentDevice ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddDevice;