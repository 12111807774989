import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import AddDevice from './AddDevice';
import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'

function Devices() {
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null); // Holds the device to edit
  const [loading, setLoading] = useState(true); // Loading state
  const [searchQuery, setSearchQuery] = useState(''); // Search query
  const [debouncedTerm, setDebouncedTerm] = useState(searchQuery);


  // useEffect to handle the debouncing logic
  useEffect(() => {
    // Set a timeout to update the debouncedTerm after 500ms
    const handler = setTimeout(() => {
      setDebouncedTerm(searchQuery);
    }, 300); // Adjust the delay here (500ms in this case)

    // Cleanup function to clear the timeout if the user types again before the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Effect to perform the action when the debouncedTerm changes (like calling an API)
  useEffect(() => {
    if (debouncedTerm) {
      // Perform the search or any action with the debounced term
      const temp = devices.filter(
        (device) =>
          device.deviceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (device.storeCode && device.storeCode.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilteredDevices(temp);
    } else {
        // If debouncedTerm is empty, reset to show all devices
        setFilteredDevices(devices);
      }
    // eslint-disable-next-line 
  }, [debouncedTerm]);

  useEffect(() => {
    const unsubscribeDevices = firestore.collection('cafeTVs').onSnapshot(snapshot => {
      const deviceList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDevices(deviceList);
      setFilteredDevices(deviceList);
      setLoading(false); // Set loading to false once data is fetched
    });

    const unsubscribeMenuItems = firestore.collection('menuItems').onSnapshot(snapshot => {
      const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMenuItems(items);
    });

    return () => {
      unsubscribeDevices();
      unsubscribeMenuItems();
    };
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      await firestore.collection('cafeTVs').doc(id).delete();
    }
  };

  const handleEdit = (device) => {
    setCurrentDevice(device); // Set current device for editing
    setShowAdd(true);
  };

  return (
    <div className="m-auto p-10 max-w-screen-xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Devices</h2>
        <button
          onClick={() => {
            setCurrentDevice(null); // Clear current device to add a new one
            setShowAdd(true);
          }}
          className="bg-teal-500 text-white px-4 py-2 rounded"
        >
          Add Device
        </button>
      </div>
      
      {/* Search Bar */}
      <div className="flex items-center border-2 border-grey-500 rounded-lg px-3 py-2 w-full mb-4">
      <MagnifyingGlassIcon className="h-5 w-5 text-black font-bold" />
      <input
        className="ml-2 w-full outline-none bg-transparent"
        type="text"
        placeholder="Search by Device Name or Store Code"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      </div>
      {showAdd && (
        <AddDevice
          onClose={() => setShowAdd(false)}
          menuItems={menuItems}
          currentDevice={currentDevice} // Pass current device if in edit mode
        />
      )}

      {/* Loader */}
      {loading ? (
        <div className="text-center py-4">
          <div className="loader border-t-4 border-teal-500 border-solid rounded-full w-12 h-12 mx-auto animate-spin"></div>
          <p className='mt-2'>Loading devices...</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow rounded text-left">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Device ID</th>
                <th className="py-2 px-4 border-b">Store Code</th>
                <th className="py-2 px-4 border-b">Device Name</th>
                <th className="py-2 px-4 border-b">Menu Item</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredDevices.map((device) => (
                <tr key={device.id}>
                  <td className="py-2 px-4 border-b">{device.deviceId}</td>
                  <td className="py-2 px-4 border-b">{device.storeCode || ""}</td>
                  <td className="py-2 px-4 border-b">{device.deviceName}</td>
                  <td className="py-2 px-4 border-b">
                    {menuItems.find((item) => item.id === device.menuItemId)?.title || 'Unassigned'}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => handleEdit(device)}
                      className="text-teal-500 mr-2 hover:opacity-70"
                    >
                      <PencilSquareIcon className="size-8" />
                    </button>
                    <button
                      onClick={() => handleDelete(device.id)}
                      className="text-red-500 hover:opacity-70"
                    >
                      <TrashIcon className="size-8" />
                    </button>
                  </td>
                </tr>
              ))}
              {filteredDevices.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No devices found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Devices;